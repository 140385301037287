<template>
  <ValidationObserver ref="form" tag="form">
    <b-modal
      v-model="isActive"
      @hidden="close"
      hide-footer
      centered
      title="ASSIGN ADVISOR"
      header-class="p-0"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      title-tag="h3"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Assign Advisor
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="close"
            />
          </div>
        </div>
      </template>
      <b-row>
        <b-col>
          <ClientAccountHeader :account="accountClient" :client="nameClient" />
        </b-col>
      </b-row>
      <ValidationProvider rules="required" v-slot="{ errors }">
        <b-row>
          <b-col>
            <label>Advisors</label>
            <b-form-group>
              <b-select
                id="selected_advisor"
                name="selected_advisor"
                v-model="advisor"
                :options="advisors"
                label="user"
                required
                :state="errors[0] ? false : null"
                text-field="user_name"
                value-field="id"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationProvider>
      <b-row class="my-2">
        <b-col>
          <center>
            <b-button variant="success" @click="assignAdvisor">
              Assign
            </b-button>
          </center>
        </b-col>
      </b-row>
    </b-modal>
  </ValidationObserver>
</template>
<script>
// Import Components
import ClientAccountHeader from "@/views/credit-experts/views/clients/components/clients/modals/ClientAccountHeader.vue";
import ClientsService from "@/views/commons/components/clients/services/clients.services";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";

// Import Vuex
import { mapMutations, mapGetters } from "vuex";

// Import Service

export default {
  components: {
    ClientAccountHeader,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    accountClient: {
      type: String,
      required: true,
    },
    nameClient: {
      type: String,
      required: true,
    },
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.getAllAdvisors();
    this.isActive = this.active;
  },
  data() {
    return {
      isActive: false,
      advisor: null,
      advisors: [],
    };
  },
  methods: {
    ...mapMutations({
      SET_ADVISOR_HAS_CHANGED: "BoostCreditClients/SET_ADVISOR_HAS_CHANGED",
    }),
    async getAllAdvisors() {
      const { data } = await ClientDashboardService.advisorList(
        this.currentModule,
        {
          roles: "[1,2,3,11]",
          type: "1",
        }
      );
      this.advisors = data;
    },
    async assignAdvisor() {
      const isValidated = await this.$refs.form.validate();
      if (!isValidated) return;
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      const params = {
        account_id: this.clientAccountId,
        advisor_id: this.advisor,
        comment: "",
        user_id: this.currentUserId,
      };
      try {
        this.addPreloader();
        const response = await ClientsService.asignAdvisor(params);
        if (response.status === 200) {
          this.SET_ADVISOR_HAS_CHANGED(true);
          this.showSuccessSwal();
          this.close(true);
        }
      } catch (err) {
        this.showErrorSwal(err);
        console.err(err);
      } finally {
        this.removePreloader();
        this.close();
      }
    },
    close(success = false) {
      this.$emit("close", success);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    currentUserId() {
      return this.currentUser.user_id;
    },
    currentUserRole() {
      return this.currentUser.role_id;
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
</style>