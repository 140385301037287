<template>
  <b-modal
    v-model="isActive"
    title="History Of Letter"
    title-tag="h3"
    size="lg"
    header-class="p-0"
    hide-footer
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    scrollable
    @hidden="closeMe"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          History Of Letter
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeMe"
          />
        </div>
      </div>
    </template>
    <account-client-header-deb :client="client" />
    <b-table
      :items="history2"
      :fields="fields2"
      show-empty
      sticky-header="50vh"
      class="table-new-customization custom-table"
    >
      <template #cell(create_by)="data">
        <span>{{ data.item.create_by }}</span>
        <br />
        <span>{{ data.item.created_at | myGlobalWithHour }}</span>
      </template>
      <template #cell(letter)="data">
        <div v-if="moduleId === 6 || moduleId === 7">
          <a @click="openPdf(data.item)">
            <b-img
              width="20"
              height="20"
              :src="assetsImg + '/images/icons/pdf.png'"
            />
            <span class="text-primary">{{ data.item.letter }}</span>
          </a>
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge v-if="data.item.status == 'SENT'" pill variant="light-primary">
          {{ data.item.status }}
        </b-badge>
        <b-badge
          v-if="data.item.status == 'PENDING'"
          pill
          variant="light-danger"
        >
          {{ data.item.status }}
        </b-badge>
        <span v-if="data.item.sent_date != null">{{
          data.item.sent_date | myGlobalWithHour
        }}</span>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import AccountClientHeaderDeb from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/AccountClientHeaderDeb.vue";
import { mapGetters } from "vuex";
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
import LettersToClientsService from "@/views/correspondence/views/letters-to-clients/service/lettersToClients.service";

export default {
  components: {
    AccountClientHeaderDeb,
  },
  props: {
    active: { type: Boolean },
  },
  data() {
    return {
      isActive: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      fields2: [
        { key: "create_by", label: "Request By", class: "w-25" },
        { key: "observation", label: "Content" },
        { key: "letter", label: "Letter" },
        { key: "status", label: "Status" },
      ],
    };
  },
  created() {
    this.isActive = this.active;
  },
  methods: {
    closeMe() {
      this.isActive = false;
      this.$emit("close");
    },
    async openPdf(item) {
      try {
        this.addPreloader();
        let response = "";
        if (this.moduleId === 6) {
          response = await LettersToClientsService.openPdfLetterClient({
            accountid: this.$route.params.idClient,
            type: item.letter_client_type_id,
          });
        } else if (this.moduleId === 7) {
          response = await LettersToClientsService.openPdfLetterClientBc({
            accountid: this.$route.params.idClient,
            type: item.letter_client_type_id,
          });
        }
        if (response.status === 200) {
          await this.forceFileDownload(response.data, "document.pdf", "blank");
          this.removePreloader();
        }
      } catch (e) {
        console.log(e);
        this.removePreloader();
      }
    },
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      history: "ParagonClientDashboard/getHistoryEmail",
      history2: "ParagonClientDashboard/getHistoryLetter",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
