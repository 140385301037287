<template>
  <div>
    <header-slot :clients-search="true">
      <!-- Assign advisor -->
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />
          <b-button
            v-if="isCeo || isSupervisor || isTeamLeaderis"
            class="ml-1"
            :disabled="hasAdvisorAssigned || isResponsibleAccount"
            @click="openModalAssignAdvisor"
          >
            <FeatherIcon icon="UsersIcon" />
            Assign Advisor
          </b-button>
          <!-- Add Service -->
          <b-button
            :disabled="isResponsibleAccount"
            class="ml-1"
            @click="appPassKey()"
          >
            <b-icon icon="key" />
            APP
          </b-button>
          <!-- Add Service -->

          <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            right
            text="Add Service"
          >
            <template #button-content>
              <feather-icon icon="PlusIcon" />
              Add Services
            </template>
            <b-dropdown-item @click="openAddService(3)">
              Add Service
            </b-dropdown-item>
            <b-dropdown-item @click="openAddService(4)">
              Change Service
            </b-dropdown-item>
          </b-dropdown>
          <!-- Share -->
          <b-button
            v-if="isCeo || isSupervisor || isTeamLeader"
            class="ml-1"
            :disabled="isResponsibleAccount"
            @click="openModalShare"
          >
            <feather-icon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->
          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            @click="openModalProgramList"
          >
            <feather-icon icon="MonitorIcon" />
            Others Programs
          </b-button>

          <!-- Send CR -->
          <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            right
            text="Send CR"
          >
            <template #button-content>
              <feather-icon icon="FileTextIcon" />
              Request CR
              <b-badge
                v-if="countNcrForUpdate > 0"
                v-b-tooltip.hover
                title="The specialist requests a new NCR because it has already expired"
                variant="info"
                class="custom-badge-number"
                >{{ countNcrForUpdate }}</b-badge
              >
              <b-badge
                v-if="cPendingNcrByClient > 0"
                v-b-tooltip.hover
                title="The client has pending requests"
                variant="warning"
                class="custom-badge-number"
                >{{ cPendingNcrByClient }}</b-badge
              >
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
            <b-dropdown-item @click="showCreditReport = true">
              History
              <b-badge v-if="cPendingNcrByClient > 0" variant="warning">{{
                cPendingNcrByClient
              }}</b-badge>
            </b-dropdown-item>
          </b-dropdown>
          <!-- New items -->
          <b-button
            class="ml-1 custom-button"
            @click="openModalCreateNewItems()"
          >
            <div class="d-flex">
              <div>New Items</div>
              <div>
                <b-badge
                  v-if="G_COUNTER_NEW_ITEMS > 0"
                  pill
                  variant="danger"
                  class="ml-1"
                >
                  {{ G_COUNTER_NEW_ITEMS }}
                </b-badge>
              </div>
            </div>
          </b-button>
          <!-- Send -->
          <b-dropdown :disabled="isResponsibleAccount" class="ml-1" right>
            <template #button-content>
              <feather-icon icon="SendIcon" />
              Send
            </template>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span @click="openModalSendSms">Send SMS</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistorySms"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalSendEmail"
                  >Send Email</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryEmail"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalLetter"
                  >Send Letter</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryLetter"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="client.status_call_round != 1 || validateStatusCall"
              @click="openModalCallRound(client.status_call_round)"
            >
              Call Round
            </b-dropdown-item>

            <b-dropdown-item style="cursor: none">
              <div class="d-flex justify-content-between align-items-center">
                <span style="width: 6px" @click="openModalSendStatement"
                  >STATEMENT</span
                >
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryStatement"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="d-flex justify-content-between px-3 pt-2">
        <!--  -->
        <div>
          <b-skeleton-wrapper :loading="!validateAccount">
            <template #loading>
              <b-card no-body>
                <b-skeleton width="85%" style="min-width: 124px" />
                <b-skeleton width="55%" style="min-width: 95px" />
                <b-skeleton width="70%" style="min-width: 110px" />
              </b-card>
            </template>
          </b-skeleton-wrapper>
          <b-form-group style="margin-bottom: 5px !important">
            <template v-if="validateAccount">
              <p>START DATE: {{ client.start_date | myGlobal }}</p>
              <span
                style="font-weight: bolder; font-size: 17.5px"
                @click="isResponsibleAccount ? '' : openEditLead()"
                :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                >{{ client.client_name }}</span
              >
              <b-badge
                v-if="client.advisor_connection_id"
                class="ml-1"
                variant="light-info"
              >
                IN CONNECTION
              </b-badge>
              <br />
              <div class="d-flex">
                <span style="font-size: 14.5px">
                  {{ client.account }} | &nbsp;
                </span>
                <div class="d-flex align-items-center" style="gap: 5px">
                  <StatusClientAccount />
                </div>
              </div>
            </template>
          </b-form-group>

          <div
            v-if="client.track_id != null"
            class="mt-1"
            style="margin: 0px; padding: 0px"
            lg="auto"
          >
            <span
              >TRACKING: {{ client.tracking_start }} -
              {{ client.tracking_end }}</span
            >
            <b-row>
              <b-col lg="auto">
                <b-input-group prepend="FILE">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <feather-icon
                        v-if="client.file_status == null"
                        class="text-secondary cursor-pointer"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 1)"
                      />
                      <feather-icon
                        v-else-if="client.file_status != null"
                        v-b-tooltip.hover.left="client.file_status"
                        class="cursor-pointer text-success"
                        size="21"
                        icon="CheckCircleIcon"
                        @click="checkstatus(client.track_id, 1, 2)"
                      />
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="CALL">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="danger"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              1,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-badge>
                        <b-badge
                          :id="'call-target-' + client.track_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.call_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_calls)
                              ? JSON.parse(client.dates_calls).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'call-target-' + client.track_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(
                                client.dates_calls
                              )"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          class="cursor-pointer"
                          variant="success"
                          @click="
                            checkstatus(
                              client.track_id,
                              2,
                              2,
                              client.dates_calls
                            )
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto">
                <b-input-group prepend="SMS">
                  <b-input-group-append>
                    <div
                      :style="isDarkSkin ? 'opacity: 0.6' : ''"
                      class="form-group-input2 d-flex align-content-center align-items-center"
                    >
                      <div class="text-center">
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="danger"
                          class="cursor-pointer"
                        >
                          <feather-icon
                            icon="MinusIcon"
                            @click="
                              checkstatus(
                                client.track_id,
                                3,
                                1,
                                client.dates_sms
                              )
                            "
                          />
                        </b-badge>
                        <b-badge
                          :id="'sms-target-' + client.tracking_id"
                          variant="primary"
                          style="
                            width: 40px;
                            margin-left: 0.3rem;
                            margin-right: 0.3rem;
                          "
                        >
                          {{ client.sms_status }}
                        </b-badge>
                        <b-tooltip
                          v-if="
                            (JSON.parse(client.dates_sms)
                              ? JSON.parse(client.dates_sms).length
                              : 0) > 0
                          "
                          :key="cont"
                          :target="'sms-target-' + client.tracking_id"
                          triggers="hover"
                          placement="left"
                        >
                          <div>
                            <span
                              v-for="(item, i) in JSON.parse(client.dates_sms)"
                              :key="i"
                            >
                              {{ item }}<br />
                            </span>
                          </div>
                        </b-tooltip>
                        <b-badge
                          v-if="client.track_finish == null ? true : false"
                          variant="success"
                          class="cursor-pointer"
                          @click="
                            checkstatus(client.track_id, 3, 2, client.dates_sms)
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-badge>
                      </div>
                    </div>
                  </b-input-group-append> </b-input-group
              ></b-col>
              <b-col lg="auto" class="d-flex align-items-center">
                <b-button
                  v-if="client.track_finish == null ? true : false"
                  :disabled="
                    !(
                      client.call_status > 0 &&
                      client.file_status != null &&
                      client.sms_status > 0
                    )
                  "
                  size="sm"
                  variant="primary"
                  @click="finishTracking(client.track_id)"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CheckCircleIcon"
                  />FINISH</b-button
                ></b-col
              >
            </b-row>
          </div>
        </div>

        <div v-if="validateAccount" class="w-25">
          <EfectivityCard v-if="showEfectivity" :client="client" />
        </div>
      </div>

      <b-nav pills class="ml-3 mb-0 mt-2 custom-tab-amg">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'personal-information-credit-experts-general' }
              : { name: 'personal-information-credit-experts' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'pay-credit-experts-general' }
              : { name: 'pay-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Notes'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'task-notes-credit-experts-general' }
              : { name: 'task-notes-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Notes</b-nav-item
        >

        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-files-credit-experts-general' }
              : { name: 'client-files-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'acr-credit-experts-general' }
              : { name: 'acr-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Credit'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'dis-credit-experts-general' }
              : { name: 'dis-credit-experts' }
          "
          exact
          :active="isActiveDispute"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'call-log-client-credit-experts-general' }
              : { name: 'call-log-client-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isCallLog"
          >Call Log</b-nav-item
        >
        <b-nav-item
          v-if="validationArrRoles"
          v-b-tooltip.hover.bottomright="'Customer Tickets'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-tickets-credit-experts-general' }
              : { name: 'client-tickets-credit-experts' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Customer Tickets
          <span
            v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                  ? '99+'
                  : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
              "
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
    </b-card>
    <hr class="border-primary border-3 px-0 mt-0" />
    <!-- <b-card class="border-top-primary border-3 border-table-radius px-0"> -->
    <router-view v-if="showViews" />
    <!-- </b-card> -->

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <!-- Send CR -->
    <!-- <modal-create-ncr
      v-if="modalCreateNcr"
      :modal-create-ncr="modalCreateNcr"
      :typencr="1"
      @closeModalCreateNcr="closeModalCreateNcr"
    />

    <modal-create-ncr-realtor
      v-if="modalCreateNcrRealtor"
      :typencr="1"
      @closeMe="changeStatusModalCreateNcrRealtor(false)"
      @closeModalCreateNcrRealtor="closeModalCreateNcrRealtor"
    /> -->

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncr-type="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />

    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <send-letter-modal v-if="modalOpenLetter" @close="closeModalLetter" />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <send-to-call-rounds
      v-if="modalCallRound"
      :modal-call-round="modalCallRound"
      :name-leads="clientsSms"
      :type-call="0"
      @validateStatus="validateStatus"
      @closeModal="closeModalCallRound"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <letter-modal-tracking
      v-if="activeModalHistoryLetter"
      :active="activeModalHistoryLetter"
      @close="closeModalHistoryLetter"
    />

    <modal-send-statement
      v-if="modalSendStatement"
      :modal-status-report="modalSendStatement"
      @closeModalStatus="closeModalSendStatement"
    />

    <modal-history-statement
      v-if="modalHistoryStatement"
      :active="modalHistoryStatement"
      @close="closeModalHistoryStatement"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <assign-advisor-modal
      v-if="activeModalAssignAdvisor"
      :account-client="accountClient"
      :name-client="nameClient"
      :client-account-id="clientAccountId"
      :active="activeModalAssignAdvisor"
      @close="closeModalAssignAdvisor"
    />

    <b-modal
      :title="`App Key for ${client.client_name}`"
      size="md"
      hide-footer
      title-tag="h4"
      :visible="modalShowAppKey"
      header-class="p-0"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      @hidden="
        modalShowAppKey = false;
        showChangePassword = false;
      "
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            App key for {{ client.client_name }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="
                modalShowAppKey = false;
                showChangePassword = false;
              "
            />
          </div>
        </div>
      </template>
      <b-row class="mb-2">
        <b-col cols="6" class="d-flex align-items-center">
          <div>Status</div>
          <div class="ml-1">
            <b-badge
              :variant="
                status_in_app == 'Inactive' ? 'light-danger' : 'light-success'
              "
            >
              {{ status_in_app }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center clickable">
          <div>Status</div>
          <div class="ml-1">
            <b-badge variant="primary" @click="changePassword(null)">
              Alter password <b-icon icon="key" />
            </b-badge>
          </div>
        </b-col>
      </b-row>
      <hr v-if="showChangePassword" />
      <b-row v-if="showChangePassword">
        <b-col cols="4"> New Password </b-col>
        <b-col v-if="Boolean(password)" cols="8" class="text-right text-info">
          Old Password: <strong> {{ password }} </strong>
        </b-col>
        <validation-observer ref="form" class="w-100">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            class="w-100"
          >
            <b-col cols="12" class="d-flex align-items-center">
              <b-input-group>
                <b-form-input
                  v-model="new_password"
                  placeholder="New Password..."
                  :state="errors[0] ? false : null"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="changePassword(1)">
                    Set Password
                    <feather-icon icon="KeyIcon" class="ml-1" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </validation-provider>
        </validation-observer>
      </b-row>
    </b-modal>

    <credit-report-modal
      v-if="showCreditReport"
      :lead="clientNcr"
      @closeModal="showCreditReport = false"
    />

    <list-case-modal
      v-if="modalListCourtInfo"
      :modal-list-court-info="modalListCourtInfo"
      :lead-id="client.lead_id"
      :modul-id="moduleId"
      @closeModalListCourtInfo="closeModalListCourtInfo"
    />

    <new-items-modal
      v-if="showModalNewItems"
      :clientData="clientData"
      :editMode="true"
      @close="showModalNewItems = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import LetterModalTracking from "@/views/credit-experts/views/clients/dashboard/information-client/modals/LetterModalTracking.vue";
// Import Modals

import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare.vue";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";

import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import SendToCallRounds from "@/views/credit-experts/views/clients/components/clients/modals/SendToCallRounds.vue";
import moment from "moment";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import SendLetterModal from "@/views/credit-experts/views/clients/dashboard/information-client/modals/SendLetterModal.vue";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";
import EfectivityClient from "@/views/commons/components/clients/components/efectivity/EfectivityClient.vue";

import ModalSendStatement from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";
import ClientCeDashboard from "@/views/credit-experts/views/clients/dashboard/services/clients.ce.dashboard.services";
import AssignAdvisorModal from "@/views/credit-experts/views/clients/components/clients/modals/AssignAdvisorModal.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
import EfectivityCard from "@/views/commons/components/ncr-efectivity/CardNcrEfectivity.vue";
import NewItemsModal from "@/views/commons/components/request-ncr/views/modals/NewItemsModal.vue";
import CreditReportModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/CreditReportModal.vue";
export default {
  components: {
    SendToCallRounds,
    ModalShare,
    ModalProgramList,
    // ModalCreateNcr,
    // ModalCreateNcrRealtor,
    ModalSendSms,
    ModalSendEmail,
    LeadUpdate,
    ModalHistory,
    SendLetterModal,
    ModalHistorySms,
    ModalHistoryEmail,
    LetterModalTracking,
    "modal-send-statement": ModalSendStatement,
    ModalHistoryStatement,
    ModalAddService,
    AssignAdvisorModal,
    StatusAccount,
    EfectivityClient,
    ModalCreateNcrRequest,
    CourtInfoAction,
    StatusClientAccount,
    EfectivityCard,
    NewItemsModal,
    CreditReportModal,
  },

  data() {
    return {
      clientNcr: {},
      showCreditReport: false,
      showViews: false,
      modalListCourtInfo: false,
      cont: 0,
      validateStatusCall: false,
      clientsSms: [],
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalOpenLetter: false,
      modalCallRound: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      activeModalHistorySms: false,
      activeModalHistoryEmail: false,
      activeModalHistoryLetter: false,
      activeModalAssignAdvisor: false,
      // open modal Add Service
      modalOpenAddService: false,
      event: null,
      detailsClient: {},
      typeModal: null,
      // open modal Add Service
      modalSendStatement: false,
      modalHistoryStatement: false,
      modalShowAppKey: false,
      showChangePassword: false,
      password: "",
      date_active: "",
      status_in_app: "Inactive",
      new_password: "",
      typeAddChange: null,
      selectedNcr: null,
      countNcrForUpdate: null,
      showEfectivityDetails: false,
      showModalNewItems: false,
      clientData: {},
    };
  },
  computed: {
    isPersonalInformation() {
      return this.$route.fullPath.includes("personal-information");
    },
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },
    isService() {
      return this.$route.matched?.[2]?.name == "services-client-boost-credit";
    },

    isActiveDispute() {
      if (this.$route.matched[0].meta.general) {
        return this.$route.matched?.[1]?.name == "dis-credit-experts-general";
      }
      return this.$route.matched?.[2]?.name == "dis-credit-experts";
    },
    validationArrRoles() {
      if (
        this.currentUser.arrRoles.find(
          (rol) =>
            (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
            rol.module_id !== 24 &&
            rol.user_id !== 38
        )
      ) {
        return true;
      }
      return false;
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      G_COUNTER_NEW_ITEMS: "NewItemsStore/G_COUNTER_NEW_ITEMS",
      missingFiles: "ReportStore/G_MISSING_FILES",
    }),
    creditCounter() {
      return (
        // this.G_COUNT_ANALYSIS_CR.toRemove +
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.missingFiles +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, //full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
    showSendToConnectionButton() {
      return !this.isInConnection;
    },
    backgroundConnectionClient() {
      return this.isInRecycle
        ? "rgb(73, 0, 0) !important"
        : this.isInConnection && this.client.status_connection == "HOLD"
        ? "rgb(255, 68, 0)"
        : this.isInConnection && this.client.status_connection == "LOYAL"
        ? "rgb(0, 17, 87)"
        : this.isInConnection && this.client.status_connection == "RECOVERED"
        ? "rgba(75, 181, 67, 0)"
        : "rgba(34, 41, 47, 0)";
    },
    isInRecycle() {
      return !!(
        this.client.in_connection == "CONNECTION" &&
        this.client.status_connection == "RECYCLE"
      );
    },
    showEfectivity() {
      return this.client.id != undefined;
    },
    isInConnection() {
      return this.client.in_connection == "CONNECTION";
    },
    hasAdvisorAssigned() {
      return this.client.advisor_id !== null;
    },
    nameClient() {
      return this.client.client_name;
    },
    accountClient() {
      return this.client.account;
    },
    clientAccountId() {
      return this.client.id;
    },
    accountState() {
      return {
        status: this.client.status,
        in_connection: this.client.in_connection,
        validate_sp: this.client.validate_sp,
      };
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    this.addPreloader();
    const { data } = await ClientsOptionsServices.getMissingFiles({
      accountId: this.$route.params.idClient,
    });
    await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.$route.params.idClient,
      module_id: this.moduleId,
    });
    await this.$store.dispatch(
      "NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO",
      { account_id: this.$route.params.idClient }
    );
    try {
      await this.getInformation(this.$route.params.idClient);
      await this.countRequestNcrForUpdate();
      await this.infoLead();
      // await this.getCountFiles()
      // await this.getCounterDispute()
      this.showViews = true;
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    if (this.$route.matched[0].meta.general) {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    }
    await this.A_GET_NEW_ITEMS_COUNTER({
      client_account_id: this.$route.params.idClient,
    });

    await this.getLead(this.client.lead_id);
    // set client NCR
    this.clientNcr = {
      client_account_id: this.client.id,
      account: this.client.account,
      client_id: this.client.client_id,
      lead_id: this.client.lead_id,
      ...this.S_LEAD,
    };
    await this.A_COUNT_PENDING_WP_CLIENT({
      client_account_id: this.$route.params.idClient,
    });
    await this.getUpdates();
    await this.getCountAccountsToRemove();
  },
  async mounted() {
    // this.A_COUNT_ANALYSIS_CR({idClient: this.$route.params.idClient, typeView: 'REMOVE'});
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });
    await this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    async getLead(lead_id) {
      try {
        await this.A_GET_LEAD({ id: lead_id });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getInformation(val) {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: val,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: val,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: val,
        }),
      ]);
    },
    async changePassword(type) {
      if (type === 1) {
        const validate = await this.$refs.form.validate();
        if (!validate) return;
        const result = await this.showConfirmSwal();
        const params = {
          account_id: this.$route.params.idClient,
          password: this.new_password,
        };
        if (result.value) {
          try {
            await ClientCeDashboard.changePassword(params);
            this.password = this.new_password;
            this.new_password = "";
            this.showSuccessSwal();
          } catch (error) {
            this.showErrorSwal(error);
          }
        }
      }
      this.showChangePassword = !this.showChangePassword;
    },
    async appPassKey() {
      const { data } = await ClientCeDashboard.getInformationApp({
        account_id: this.$route.params.idClient,
      });
      this.status_in_app = data[0].date_activation ? "Active" : "Inactive";
      this.date_active = data[0].date_activation;
      this.password = data[0]?.password ?? "";
      this.modalShowAppKey = true;
    },
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await trackingListService.finishTrackingList({
            id,
          });
          if (data.status === 200) {
            this.cont++;
            this.client.track_finish = data.data[0].track_finish;

            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async checkstatus(id, type, status, datos) {
      try {
        if (this.client.track_finish == null) {
          const date = moment().format("MM/DD/YYYY hh:mm A");
          const array = datos == null ? [] : JSON.parse(datos);
          if (status === 2) {
            array.push(date);
          } else {
            array.pop();
          }

          const data = await trackingListService.updateStatusTracking({
            id,
            type,
            status,
            newval: array,
          });
          if (data.status === 200) {
            this.client.file_status = data.data[0].file_status;
            this.client.call_status = data.data[0].call_status;
            this.client.dates_calls = data.data[0].dates_calls;
            this.client.sms_status = data.data[0].sms_status;
            this.client.dates_sms = data.data[0].dates_sms;
          }
        }
      } catch (e) {}
    },
    validateStatus() {
      this.validateStatusCall = true;
    },
    async refresh() {
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    closeModalHistoryLetter() {
      this.activeModalHistoryLetter = false;
    },
    openModalCreateNewItems() {
      this.clientData = {
        client_name: this.client.client_name,
        client_account: this.client.account,
        client_account_id: this.client.id,
        lead_id: this.client.lead_id,
      };
      this.showModalNewItems = true;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    async openModalHistoryLetter() {
      await this.getHistoryLetter(this.$route.params.idClient);
      this.activeModalHistoryLetter = true;
    },

    ...mapActions({
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      getHistoryLetter: "ParagonClientDashboard/getHistoryLetter",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
      A_GET_NEW_ITEMS_COUNTER: "NewItemsStore/A_GET_NEW_ITEMS_COUNTER",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
    }),
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async getSellers() {
      try {
        await this.A_GET_SELLERS({
          modul: this.moduleId,
          body: { roles: "[]", type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getSellers:", error);
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async infoLead() {
      // await all promises
      await Promise.all([
        this.getLeadEdit(),
        this.getPrograms(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
        this.getOwners(),
        this.getSellers(),
      ]);
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },

    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
      this.countRequestNcrForUpdate();
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },
    changeStatusModalCreateNcrRealtor(visible) {
      this.modalCreateNcrRealtor = visible;
    },

    async openModalCallRound(status) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id: this.client.id,
            name: this.client.client_name,
            account: this.client.accounts,
            nameAccount: this.client.account,
            nameProgram: this.client.program_name,
          },
        ];
        this.modalCallRound = true;
      }
    },
    closeModalCallRound() {
      this.modalCallRound = false;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalLetter() {
      this.modalOpenLetter = true;
    },
    closeModalLetter() {
      this.modalOpenLetter = false;
    },
    openModalSendStatement() {
      this.modalSendStatement = true;
    },
    closeModalSendStatement() {
      this.modalSendStatement = false;
    },
    openModalHistoryStatement() {
      this.modalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.modalHistoryStatement = false;
    },
    // open modal add service
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    ...mapActions({
      // A_COUNT_ANALYSIS_CR: 'AnalysisCrStore/A_COUNT_ANALYSIS_CR'
    }),
    async createEvent() {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: this.leadId,
      });
      this.event = response.id;
    },
    async openAddService(typeModal) {
      const result = await this.showConfirmSwal(
        typeModal === 3
          ? "Are sure want to add new service?"
          : "Are sure want to change service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent();
        const data = {
          lead_name: this.client.client_name,
          lead_id: this.client.lead_id,
          accounts: [
            {
              program: "Credit Experts",
            },
          ],
        };
        this.modalGeneralService(data, typeModal);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    async openModalProgram(data) {
      if (data === undefined) return;
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.client.id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.client.module,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-connection-client" })
                .catch((err) => {
                  console.log(err);
                });
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    // end modal add service
    openModalAssignAdvisor() {
      this.activeModalAssignAdvisor = true;
    },
    closeModalAssignAdvisor() {
      this.activeModalAssignAdvisor = false;
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },
  },
  watch: {
    "$route.params.idClient": async function (val) {
      if (val) {
        this.addPreloader();
        await this.getInformation(val);
        this.removePreloader();
        this.validateImg = true;
        this.validateAccount = true;
      }
    },
  },
};
</script>

<style>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px !important;
}
.bg-dark {
  background-color: #111113 !important;
}
.bg-light {
  background-color: #efefef !important;
}
.content-header .content-header-right button {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.content-header .content-header-right .court-info button {
  border-color: #82868b !important;
  background-color: #82868b !important;
}
.content-header .content-header-right .court-info-court button {
  border-color: #8f5fe8 !important;
  background-color: #8f5fe8 !important;
}
</style>
<style scoped>
.send-cr >>> .dropdown-toggle {
  padding: 0.6rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
.custom-badge-number {
  margin: 0 5px;
}
</style>
