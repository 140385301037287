<template>
  <b-row class="my-1">
    <b-col>
      <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
        <p
          class="m-0"
          style="gap: 5px"
        >
          Client | {{ client }}
        </p>
      </div>
    </b-col>
    <b-col>
      <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
        <p
          class="m-0"
          style="gap: 5px"
        >
          Account | {{ account }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ClientAccountHeader',
  props: {
    account: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
